<template>
  <div class="flex h-full items-center justify-center">
    <div class="card w-5/6 p-4 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-1/3">
      <h1 class="mb-4">{{ t("headings.complete") }}</h1>
      <div v-if="step == 0">
        <custom-form
          :formElements="formElements"
          formStyle="block"
          @submitForm="(data) => next(data)"
        />
      </div>
      <div v-else>
        <custom-form
          :formElements="passwordFormElements"
          formStyle="block"
          @submitForm="complete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import CustomForm from "../components/form/CustomForm.vue";
import languageList from "../i18n/list";
export default {
  components: { CustomForm },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const step = ref(0);

    const formElements = ref([
      {
        class: "col-span-12",
        element: "input",
        id: "first-name",
        label: "first_name",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "firstName",
      },
      {
        class: "col-span-12",
        element: "input",
        id: "last-name",
        label: "last_name",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "lastName",
      },
      {
        class: "col-span-12",
        element: "select",
        id: "language",
        label: "language",
        optionLabel: ["name"],
        options: languageList,
        rules: ["isRequired"],
        value: "fi",
        variable: "language",
      },
      {
        class: "col-span-12",
        element: "input",
        id: "phone",
        label: "phone",
        rules: ["isRequired", "isPhoneNumber"],
        type: "text",
        value: "",
        variable: "phone",
      },
      {
        class: "col-span-12 justify-self-end w-1/3",
        element: "button",
        id: "submit-button",
        label: "next",
        textVariant: "light",
        type: "submit",
        variant: "indigo",
      },
    ]);

    const passwordFormElements = ref([
      {
        class: "col-span-12",
        element: "input",
        id: "password",
        label: "password",
        rules: ["isRequired"],
        type: "password",
        value: "",
        variable: "password",
      },
      {
        class: "col-span-12",
        element: "input",
        id: "password-again",
        label: "password_again",
        match: "password",
        rules: ["isRequired", "isMatch"],
        type: "password",
        value: "",
        variable: "passwordAgain",
      },
      {
        class: "col-span-12",
        element: "button",
        id: "submit-button",
        label: "complete",
        textVariant: "light",
        type: "submit",
        variant: "indigo",
      },
    ]);

    const data = ref(null);

    const next = (personalData) => {
      data.value = personalData;
      step.value++;
    };

    const complete = (passwordData) => {
      data.value.password = passwordData.password;
      store.dispatch("authentication/activate", data.value);
    };

    return {
      complete,
      formElements,
      next,
      passwordFormElements,
      step,
      t,
    };
  },
};
</script>
